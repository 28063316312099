@import '../../style.scss';

.drawerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 100%;
  padding: 16px 32px;
}

.drawerHeader {
  margin-left: auto;
  margin-bottom: 16px;
}

.buttonsArea {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 48px;
}

.navigationButton {
  color: $color-white !important;
}

.redText {
  color: $color-red !important;
}