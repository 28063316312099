@import '../../style';

.newCodeContainer {
  width: 300px;
  height: 100px;
  margin: 20 auto;
  border: 1px solid black;
  border-radius: 5px;
}

.button {
  left: 10px;
}

.accessCodeText {
  font-weight: bold;
  font-size: 18px;
}
