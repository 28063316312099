@import '../../style.scss';

.defaultGrayText {
  color: $color-gray;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}
