.inputField {
  width: 100%;
  height: 40px !important;
  max-height: 40px !important;
}

.helperBox {
  height: 30px !important;
}

// Remove autocomplete discoloration in chrome
.innerInputField:-webkit-autofill,
.innerInputField:-webkit-autofill:hover,
.innerInputField:-webkit-autofill:focus,
.innerInputField:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
