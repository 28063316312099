@import '../../style.scss';

@mixin button {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: 'Outfit' !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  min-width: 0px !important;
}

.contained {
  @include button;
  background-color: $color-dark-blue-700 !important;
  box-shadow: none !important;

  &:hover {
    background-color: $color-gray-blue !important;
    box-shadow: none !important;
  }

  &:active, &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    color: $neutral-color-gray-700 !important;
    background-color: $neutral-color-gray-300 !important;
    box-shadow: none !important;
  }
}

.outlined {
  @include button;
  background-color: $color-white !important;
  color: $color-gray-950 !important;
  border: 1px solid $color-gray-200 !important;
  box-shadow: none !important;

  &:hover {
    border: 1px solid $color-gray-300 !important;
    box-shadow: none !important;
  }

  &:active, &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    border: none !important;
    background-color: $color-gray-50 !important;
    color: $color-gray-400 !important;
    box-shadow: none !important;
  }
}

.text {
  @include button;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: $color-gray-950 !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;

  &:hover {
    color: $color-gray-300 !important;
    box-shadow: none !important;
  }

  &:active, &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    color: $color-gray-300 !important;
    box-shadow: none !important;
  }
}
