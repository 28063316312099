@import '../../style.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  padding: 2px 6px;
  background: $color-gray-50;
  border: 1px solid $color-gray-100;
  border-radius: 100px;
  width: fit-content;
  flex-wrap: nowrap;
}

.text {
  white-space: nowrap;
  color: $color-gray-900;
}
