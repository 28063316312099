@import '../../style';

.navBar {
  width: 100%;
}

.newIcon {
  font-size: 60px !important;
}

.saveButton {
  width: 150px;
  margin-left: 10px !important;
}
