@import '../../../../style.scss';

.button {
  color: $color-dark-blue !important;
  &:disabled {
    color: hex-to-rgba($color-dark-blue, 0.25) !important;
  }
  &:hover {
    color: hex-to-rgba($color-dark-blue, 0.5) !important;
    background-color: hex-to-rgba($color-dark-blue, 0.05) !important;
  }
}
