@import '../../style.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tabs {
  display: flex;
  gap: $spacing-small;
  border-bottom: 1px solid #EAECF0;
}

.tab {
  padding: 0 $spacing-3xs $spacing-small;
  cursor: pointer;
  border: none;
  margin: 0;
  background-color: transparent;
  font-family: 'Outfit';
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: $color-gray;
  transition: color 0.3s ease;
}

.tab.active {
  color: $color-gray-blue;
  border-bottom: 2px solid $color-gray-blue;
}

.tab:not(.active):hover {
  color: $color-gray;
}

.tabPanelContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
  padding-top: $spacing-xl;
}
