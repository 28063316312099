.selectBar {
  text-align: center;
  padding-top: 20px;
}
.description {
  display: inline-block;
}
.input {
  width: 200px;
  height: 30px;
  border-radius: 5px;
}
.button {
  margin: 0 5px 5px 10px;
  font-size: 16px;
  height: 25px;
}
.buttonPush {
  margin: 10px 0 0 240px;
  font-size: 16px;
  height: 25px;
}
.select {
  font-size: 16px;
  width: 90px;
  height: 25px;
}
.selectDeviceGroup {
  font-size: 16px;
  width: 150px;
  height: 25px;
}

.title {
  font-weight: bold;
}
.sectionTitle {
  font-weight: bold;
  font-size: 17px;
}

.resultRow {
  margin-left: 20px;
  margin-top: 20px;
  line-height: 30px;
  width: 1000px;
}
.loaderContainer {
  position: absolute;
  top: 130px;
  left: 200px;
  width: 650px;
  height: 350px;
  background-color: #e0e4e8;
  z-index: 2;
  margin: auto;
  border-radius: 10px;
}
.loadingText {
  text-align: center;
  margin-top: 120px;
  font-size: 25px;
}

.container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  font-family: Roboto;
  background-color: #f0f4f8;
  width: 900px;
  border-radius: 5px;
  position: relative;
  td {
    padding: 14px 10px;
  }
}

.outerContainer {
  position: relative;
  margin: 8px;
}

.sleepInput {
  width: 250px;
}
.cbtTestInput {
  width: 100px;
}
.cohortIdInput {
  width: 50px;
}

.inputPushTitle {
  width: 550px;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 12px;
}
.inputPushBody {
  width: 400px;
  height: 100px;
  font-size: 14px;
  border-radius: 5px;
  vertical-align: top;
}

.statusOrgange {
  font-size: 16px;
  color: orange;
}
.statusRed {
  font-size: 16px;
  color: red;
}
.statusGreen {
  font-size: 16px;
  color: green;
}
.sleepTimeText {
  margin-right: 24px;
}

.sleepTimeInput {
  margin: 0 5px;
  width: 40px;
  text-align: center;
}
.sleepScheduleDescription {
  margin: 0 5px;
  width: 300px;
}
.buttonSleepTimes {
  font-size: 16px;
  height: 25px;
  position: relative;
}

.adHocInput {
  width: 650px;
  font-size: 14px;
}

.adHocTitle {
  margin-right: 49px;
}
.adHocSubTitle {
  margin-right: 18px;
}

.buttonAdHoc {
  margin: 0;
  font-size: 16px;
  height: 25px;
  top: 10px;
  position: relative;
}
