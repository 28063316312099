@import '../../../../style.scss';

.card {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: $spacing-medium;
  border: 1px solid $color-gray-200;
  border-radius: 16px;
}

.content {
  display: flex;
  flex-direction: row;
  gap: $spacing-large;
  flex-wrap: nowrap;
}

.title {
  text-wrap: nowrap;
}

.stub {
  width: $spacing-xl
}

.label {
  margin-top: $spacing-xs;
}
