@import '../../style.scss';

.quesitonIconType {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;

  &.openQuestion {
    background-color: #E0F3FE;
  }

  &.assesment {
    background-color: $color-gray-100;
  }

  &.yesNo {
    background-color: #C5FFF9;
  }

  &.number {
    background-color: $color-dark-blue-100;
  }

  &.multiNumbers {
    background-color: $color-pink-100;
  }

  &.optionSet {
    background-color: $color-orange-100;
  }
}

.icon {
  width: 12px;
  height: 12px;
}
