@import './PatientDashboardCommon.scss';

.mainContainer {
  position: relative;
  background: $color-white;
}

.mainContainer,
.mainContainer * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.patientDetailSection {
  @extend .columnContainer;
  margin-top: $spacing-xl;
}

.avatarDeviceContainer {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: $spacing-small;
  justify-content: space-between;
  margin: $spacing-medium 0;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 30px;
  border: 0.75px solid;
  border-color: $color-gray-100;
  background-color: $color-gray-50;
  white-space: nowrap;
}

.avatarAndText {
  @extend .columnContainer;
  justify-content: center;
  gap: $spacing-xs;
}

.personInitials {
  color: $color-gray;
}

.patientPresenceTopContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: $spacing-small;
  max-width: 400px;
  padding: $spacing-xs;
  background: $color-gray-50;
  border-radius: 16px;
  margin-top: -40px;
}

.deviceIconContainer {
  padding: $spacing-small;
  background: $color-gray-100;
  border-radius: 100px;
}

.deviceIcon {
  width: 20px;
  height: 20px;
  background: url(../../assets/images/deviceBlue.png)
    no-repeat center;
  background-size: cover;
}

.presenceContainer {
  @extend .columnContainer;
  justify-content: center;
  gap: 0;
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: $spacing-small;
}

.addButton {
  padding: $spacing-xs $spacing-small !important;
  line-height: 20px !important;
  text-transform: none !important;
}

.dropDownButton {
  //border width compensation
  padding: 7px 11px !important;
}

.addIcon {
  color: $color-white;
}

.redText {
  color: $color-red;
}

.overviewTextContainer {
  margin-top: $spacing-xl;
  margin-bottom: $spacing-large;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1440px;
  gap: $spacing-large;
  margin: 0 auto $spacing-xl;
}

.sleepOptionsContainer {
  @extend .columnContainer;
  align-items: stretch;
  gap: $spacing-large;
}

.HorizontalTabsButton {
  font-size: 16px !important;
  line-height: 24px !important;
}
