@import '../../../style.scss';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 32px;
  padding: 0 24px;
  gap: 10px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
