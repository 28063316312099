@import '../../PatientDashboardCommon.scss';

$chartHeight: 466px;

.topContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: $spacing-xl;
}

.leftContainer {
  min-width: 250px;
  max-width: 400px;
}

.rightContainer {
  min-width: 500px;
  flex: 1;
}

.insightsTextContainer {
  margin-bottom: $spacing-medium;
}

.metricsContainer {
  margin-top: $spacing-xl;
}

.metricsValueText {
  color: $color-gray-950;
}

.metricsValueContainer {
  display: flex;
  justify-content: flex-end;
}

.metricsGridRow {
  padding: $spacing-small 0;
  border-bottom: 1px solid $color-gray-100;
}

.tab {
  font-size: 14px !important;
  line-height: 20px !important;
  color: $color-gray !important;
  text-transform: none !important;
}

.tab.active {
  color: $color-gray-blue !important;
  background-color: $color-gray-50;
}

.chartContainer {
  height: $chartHeight;
  margin-top: $spacing-medium;
}

.noDataChartContainer {
  height: $chartHeight;
  margin-top: $spacing-large;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: $spacing-xl;
  background-color: $color-gray-50;
  border-radius: 16px;
}

:global(div.google-visualization-tooltip) {
  border-width: 0 !important;
  border-radius: 16px !important;
  font-family: 'Outfit';
  box-shadow: 0 12px 40px 0 #0000001F !important;
}
