@import '../../style.scss';

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 524px;
  margin-bottom: 32px;
}

.subContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formRow {
  display: flex;
  gap: 16px;
}

.labelSelect {
  font-family: 'Outfit';
  color: $color-gray-700;
  font-weight: 400;
  margin-bottom: 2px;
  padding-left: 4px;
  font-size: 14px;
  line-height: 20px;
}

.button {
  align-self: flex-end !important;
}

.apiError {
  margin-top: 20px !important;
  color: $color-red !important;
}

.successMessage {
  margin-top: 20px !important;
  color: $color-dark-blue !important;
}
