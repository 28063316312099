@import '../../../../style.scss';


.tableCell {
  line-height: 1;
  padding: $spacing-medium $spacing-xs;
}

.cellBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 0.75px solid;
  border-color: #E1E3E5;
  background-color: $color-gray-50;
  color: $color-gray;
  margin-right: $spacing-small;
}

.patientPresenceStatus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.patientPresenceTime {
  margin-left: $spacing-medium;
}

.octagonImg {
 width: 20px;
 margin-right: $spacing-2xs;
}

.dotImg {
  width: 10px;
  margin-right: $spacing-2xs;
  height: 10px;
}

@mixin cellTextBase {
  min-width: 36px;
}

.cellText {
  @include cellTextBase;
}

.timeText {
  margin-left: 0;
  margin-right: 0;
  text-wrap: nowrap;
  color: $color-gray;
}

@mixin deviceStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 0.75px solid;
  padding: 0 $spacing-2xs;
}

.deviceStatusActive {
  @include deviceStatus;
  border-color: $color-light-green-700;
  color: $color-light-green-700;
  background-color: $color-light-green-300;
  min-width: 60px;
}

.cellTextDeviceStatusActive {
  @include cellTextBase;
  color: $color-light-green-700;
  text-align: center;
}

.deviceStatusOffline {
  @include deviceStatus;
  border-color: $color-gray-900;
  color: $color-gray-900;
  background-color: $color-gray-100;
  min-width: 60px;
}

.cellTextDeviceStatusOffline {
  @include cellTextBase;
  color: $color-gray-900;
  text-align: center;
}

.deviceStatusError {
  @include deviceStatus;
  border-color: $color-red-700;
  color: $color-red-700;
  background-color: $color-red-100;
  text-align: center;
}

.cellTextDeviceStatusError {
  @include cellTextBase;
  color: $color-red-700;
}
