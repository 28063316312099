$default-primary-color: #0c2545;

.root {
  flexgrow: 1;
  flex-grow: 1;
}

.imageGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: $default-primary-color;
}

.mainHeader,
.instructionHeader {
  text-align: center !important;
  @media (min-width: 300px) {
    text-align: left !important;
  }
}

.mainHeader {
  font-size: 30px !important;
  line-height: 120% !important;
  color: $default-primary-color;
  letter-spacing: -0.05em !important;
  margin-bottom: 10px !important;
  @media (min-width: 960px) {
    font-size: 28px !important;
    line-height: 100% !important;
    margin-bottom: 4px !important;
  }
  @media (min-width: 960px) and (min-height: 460px) {
    font-size: 32px !important;
    line-height: 100% !important;
    margin-bottom: 8px !important;
  }
  @media (min-width: 960px) and (min-height: 560px) {
    font-size: 38px !important;
    line-height: 100% !important;
    margin-bottom: 10px !important;
  }
}

.instructionHeader {
  font-size: 20px !important;
  line-height: 130% !important;
  letter-spacing: -0.02em !important;
  @media (min-width: 960px) {
    font-size: 16px !important;
    line-height: 120% !important;
    letter-spacing: -0.03em !important;
  }
  @media (min-width: 960px) and (min-height: 460px) {
    font-size: 18px !important;
    line-height: 120% !important;
    letter-spacing: -0.03em !important;
  }
  @media (min-width: 960px) and (min-height: 560px) {
    font-size: 22px !important;
    line-height: 120% !important;
    letter-spacing: -0.03em !important;
  }
}

.closeInstruction {
  margin-top: 4px !important;
  letter-spacing: -0.02em !important;
  font-size: 18px !important;
  @media (min-width: 960px) {
    margin-top: 4px !important;
    font-size: 16px !important;
  }
  @media (min-width: 960px) and (min-height: 460px) {
    margin-top: 8px !important;
  }
  @media (min-width: 960px) and (min-height: 560px) {
    margin-top: 10px !important;
  }
}

.helpFooterRight,
.helpFooterLeft {
  width: 100%;
  text-align: center;
}

.helpFooterLeft,
.helpHeaderLeft,
.helpSubFooterLeft {
  color: white;
}

.snackBar {
  background-color: #f44336 !important;
}

.inputBox {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  @media (min-width: 360px) {
    width: 95%;
  }
  @media (min-width: 420px) {
    width: 80%;
  }
  @media (min-width: 1030px) {
    width: 70%;
  }
}

.marginTop {
  margin-top: 20px !important;
  @media (min-width: 960px) {
    margin-top: 0 !important;
  }
  @media (min-width: 960px) and (min-height: 460px) {
    margin-top: 20px !important;
  }
  @media (min-width: 960px) and (min-height: 560px) {
    margin-top: 50px !important;
  }
}
