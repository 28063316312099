@import '../../style.scss';

.container {
    box-shadow: 0;
}


.listItem {
    padding-left: 0;
    padding-right: 0;
    margin-left: -30px;
    margin-top: 16px;
}
.dragIcon {
    width: 18px;
    height: 18px;
    align-self: flex-start;
    margin-top: 3px;
}

.contentIcon {
  margin-right: 16px;
  align-self: flex-start;
}

.contentBox {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -4px;
}

.contentText {
    color: #0C2545;
    text-wrap: nowrap;
    max-width: 560px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.contentCategory {
    color: $color-gray;
}

.moreIcon {
    width: 20px;
    height: 20px;
    font-size: medium !important;
    align-self: flex-start;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.contextMenu {
    width: 170px;
}

.popover {
    margin-left: -155px;
}

.menuItem {
}
.menuItemRed {
    @extend .menuItem;
    color: $color-red-600;
}
