@import '../../style.scss';

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}

.subtitle {
  color: $color-gray;
  margin-bottom: 32px;
}
.textArea {
  border-radius: 5px;
  min-width: 601px;
  height: 80px;
  padding: 12px 16px;
}

.headerButton {
  width: 132px !important;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.title {
  margin-top: 24px;
}

.drawerButtonsContainer {
  display: flex;
  gap: 8px;
  align-self: flex-end;
  margin-bottom: 16px;
  margin-top: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.divider {
  width: 100%;
  position: absolute;
  height: 1px;
  background-color: #E9EDF0;
  bottom: 74px;
  margin-left: -32px;
}
