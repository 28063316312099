@import '../../style.scss';


.topContainer {
  width: 400px;
  height: 100%;
  background: $color-white;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: $spacing-medium;
  box-sizing: border-box;
}

.avatar {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  border: 0.75px solid;
  border-color: #E1E3E5;
  background-color: $color-gray-50;
  color: $color-gray !important;
  margin-bottom: $spacing-large;
}

.avatarAndText {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: $spacing-medium;
}

.grayText {
  color: $color-gray;
}

.grayEmailText {
  color: $color-gray-700;
}

.patientDetail {
  margin-bottom: $spacing-large;
}

.fieldValue {
  margin-bottom: $spacing-medium;
}
