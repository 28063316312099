@import '../../style.scss';

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.btnActivity {
  width: 123px;
  height: 36px;
  margin-top: 16px;
  align-self: flex-start;
}
.btnActivityContent {
  align-self: flex-start;
  left: -20px;
}

.btnCalendar {
  @extend .btnActivity;
  width: 87px;

}
.btnTrigger {
  @extend .btnActivity;
  width: 121px;
  margin-left: 8px !important;
}

.headerButtonsContainer {
  display: flex;
  gap: 8px;
}

.headerButton {
  width: 132px !important;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
}

.content {
  width: 660px;
}

.contentRow {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #E9EDF0;
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

}
.activityHeaderRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.activityHeaderRowContent {
  @extend .activityHeaderRow;
  flex-direction: row;
  justify-content: space-between;
}

.rowDescription {
  color: $color-gray;
  margin-bottom: 16px;
}

.drawerButtonsContainer {
  display: flex;
  gap: 8px;
  align-self: flex-end;
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  width: 649px;
  height: 100%;
  padding: 16px 32px;
}

.itemList {
  flex-direction: column;
  display: flex;
}

.calendarInput {
  height: 36px;
}

.triggerSelect {
  // height: 36px;
  padding: 0 16px;
  margin: 0px 8px !important;
  width: 270px;
}

.expirationSelect {
  padding: 0 16px;
  width: 120px;
}
.calMenu {
  min-width: 339px;
  min-height: 320px;
}
.expirationSelect {
  min-width: 93px;
}

.contextMenu {
  width: 305px;
  padding: 4px 0;
}
.contextMenuItem {
  width: 293px !important;
  height: 46px !important;
}
.menuItemText {
  font-size: 16px !important;
  line-height: 24px !important;
}
.contextIcons {
  width: 20px;
  height: 20px;
}

.drawerHeader {
  margin-left: auto;
  margin-bottom: 16px;
}

.conditionsRow {
  display: flex;
  width: 100%;
}

.datepicker {
  width: 244px;
}

.triggerSelect {
  padding-left: 0;
  padding-right: 0;
  width: 330px;
}
