.popupBox {
  display: flex;
  position: fixed;
  background: #00000050;
  z-index: 1;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.popupValueCell {
  text-align: center;
}

.labeledInput {
  margin-bottom: 5px;
}

.labeledSelect {
  margin-bottom: 10px;
  margin-top: 20px;
}

.box {
  position: relative;
  width: 700px;
  height: auto;
  max-height: 70vh;
  margin: auto auto;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.componentInfoPopup {
  margin: 8px;
  overflow-wrap: anywhere;
  word-break: normal;
  white-space: break-spaces;
}

.infoIcon {
  color: black;
  cursor: help;
  display: inline;
  margin-left: 4px;
  margin-right: 4px;
  position: relative;
}

.popupTable {
  width: 100%;
}
