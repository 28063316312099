@import '../../style';

.userCount {
  color: black;
  margin-right: 5px;
  background-color: #ffdbd5 !important;
  width: 64px;
}

.createButton {
  padding-top: 10px !important;
  padding-bottom: 0 !important;
}
