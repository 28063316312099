@import '../../style';

.trackSliderDialog {
  width: 90% !important;
  height: 100% !important;
  margin-left: auto !important;
  @media (max-width: 1280px) {
    width: 95% !important;
  }
}

.pageSliderDialog {
  width: 50% !important;
  height: 100% !important;
  margin-left: auto !important;
  @media (max-width: 1280px) {
    width: 75% !important;
  }
  @media (max-width: 960px) {
    width: 90% !important;
  }
}

.copyTile {
  height: 100% !important;
}
