.editor,
.expandedEditor,
.renderer {
  background-color: #e8f1f8 !important;
  -webkit-text-fill-color: inherit !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  min-height: 300px;
}

.renderer {
  background-color: white !important;
  border: 1px solid #c7e8f3;
  border-radius: 5px;
  padding: 10px;
}

.editor > div:nth-child(2) > div:nth-child(1) {
  width: 100% !important;
}

.highlighted {
  background-color: yellow !important;
  font-weight: normal !important;
}
