@import '../../style';

.quizOption {
  justify-content: start;
}

.quizOptionInput {
  min-width: 300px !important;
}

.adjustForLabel {
  padding-top: 12px;
}
