@import '../../../style.scss';

.button {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 1px solid $color-gray-200 !important;
  border-radius: 8px !important;
  padding: 0 !important;
}

.button:hover {
  background-color: #F5F7F9;
}

