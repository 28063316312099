@import '../../style.scss';

.imgContainer {
  padding: $spacing-xs;
  border-radius: 8px;
  margin-right: $spacing-small;
}

.noBg {
  padding: 0;
  background-color: transparent;
}

.redBg {
  background-color: $color-red-100;
}

.yellowBg {
  background-color: $color-yellow-50;
}

.iconTextContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.valueText {
  text-wrap: nowrap;
  color: $color-dark-blue-new;
}

.line2Text {
  text-wrap: nowrap;
  color: $color-gray;
}

.textContainer {
  margin-left: $spacing-xl;
}
