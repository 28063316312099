.container {
  margin: 60px !important;
}
.reviewHeader {
  font-size: 46px !important;
  margin: 20px 0 !important;
}
.headerCell {
  background-color: white !important;
}

.selectBox {
  text-align: left;
}
