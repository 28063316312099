$default-primary-color: #007eb5;

.root {
  flexgrow: 1;
  flex-grow: 1;
  margin: 8px;
}

.centeredContent {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  min-height: 100vh;
}

.statusBox,
.imageBox,
.textBox {
  text-align: center !important;
  margin-bottom: 10px;
}

.statusImage {
  min-height: 20px;
  max-height: min(5vh, 486px);
}

.kokoImage {
  min-height: 100px;
  max-height: min(50vh, 959px);
  aspect-ratio: 512 / 959;
}

.successHeader,
.failureHeader {
  font-size: 38px !important;
  color: $default-primary-color;
  top: 5px !important;
  line-height: 1 !important;
  @media (min-width: 721px) {
    font-size: 56px !important;
  }
}

.failureHeader {
  text-align: left !important;
  @media (min-width: 721px) {
    text-align: center !important;
  }
}

.bodySuccess,
.bodyFail {
  font-size: 16px !important;
}

.bodyFail {
  text-align: left !important;
  @media (min-width: 721px) {
    text-align: center !important;
  }
}

.linkButton {
  margin-bottom: 3px !important;
}
