@import '../../../../style.scss';

.tableHeader {
  line-height: 1;
  padding: 0;
  justify-content: flex-start;
  text-align: left;
  background-color: $color-white;
  pointer-events: none;
  &:hover,
  &:focus,
  &:active & {
    background-color: $color-gray-50;
  }
}

.tableHeaderPadding {
  padding: $spacing-small $spacing-xs;
}

.text {
  text-align: left;
  width: 100%;
  text-wrap: nowrap;
  color: $color-gray-950;
}

.button {
  background-color: $color-white;
  border: 0;
  pointer-events: visible;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  gap: $spacing-3xs;

  .icon {
    height: 12px;
    width: 12px;
  }

  &:hover,
  &:focus & {
    background-color: $color-gray-50;

    .icon {
      visibility: visible;
    }
  }

  &.sorted .icon {
    color: $color-gray-950;
    visibility: visible;
  }
}
