@import '../../../../style';

.listContainer {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.paper {
  background-color: #e8f1f8 !important;
  margin-bottom: 18px;
  padding: 10px;
  cursor: pointer;
}

.fullname {
  font-size: 24px !important;
}

.message {
  font-size: 16px !important;
}

.date {
  font-size: 12px !important;
}
.chatList {
  position: relative;
}

.accountStatus {
  font-size: 16px !important;
  font-weight: bold !important;
  position: absolute;
  top: 10px;
  right: 10px;
  color: burlywood;
}
.accountStatusGreen {
  @extend .accountStatus;
  color: mediumaquamarine;
}
