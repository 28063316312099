$default-primary-color: #007eb5;

.mobileHeader {
  font-size: 48px !important;
  line-height: 130% !important;
  color: $default-primary-color;
  margin-bottom: 15px !important;
  text-align: center !important;
  @media (min-width: 300px) {
    text-align: left !important;
  }
}

.titleBox {
  min-height: 46px;
  max-height: min(100vh, 46px);
  max-width: min(100%, 200px);
  aspect-ratio: 700 / 161;
}
