@import '../../style.scss';
@import '../../assets/styles/Table.scss';

.orgHeader {
  width: 300px;
}

.labelSelect {
  color: $color-gray-700;
  font-weight: 400;
  margin-bottom: 2px;
  padding-left: $spacing-2xs;
}

.pillContainer {
  position: relative;
  display: inline-block;
  margin-right: $spacing-xs;
  margin-bottom: $spacing-xs;

  &.last {
    padding-right: $spacing-xl;
  }
}

.pill {
  flex: 1;
  padding: $spacing-2xs $spacing-xl;
  border-radius: 10px;
  background-color: $color-gray-200;
  color: $color-gray-700;
  cursor: default;
  position: relative;

  &:hover {
    .pillMinus {
      display: block;
    }
  }
}

.pillMinus {
  position: absolute;
  right: $spacing-2xs;
  top: $spacing-2xs;
  padding: 0 !important;
  background-color: $color-gray-200;
  border: none;
  cursor: pointer;
  display: none;
}

.pillAdd {
  position: absolute;
  top: $spacing-2xs;
  right: $spacing-2xs;
  padding: 0 !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

