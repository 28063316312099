@import '../../style.scss';

.topContainer {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  flex: 1;
}

.container {
/*  display: flex;
  flex-direction: column;
  flex: 1;*/
}

.header {
  display: flex;
  flex-direction: column;
  gap: $spacing-large;
  align-items: start;
  justify-content: stretch;
  padding-top: $spacing-xl;
  position: sticky;
  padding-bottom: $spacing-large;
}

.headerItem {
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
  align-self: stretch;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;
  padding: 0 $spacing-xl $spacing-xl;
}
