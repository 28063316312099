@import '../../style.scss';

.topContainer {
  border: 1px solid $color-gray-200;
  border-radius: 10px;
  margin-top: $spacing-2xs;
}

.selectContainer {
  height: 44px;
}
