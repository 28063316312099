.button {
  margin: 0 5px 5px 0;
}

.container {
  margin: auto;
  width: fit-content;

  td {
    padding: 0 20px;
    text-align: center;
    div {
      background-color: unset;
      border: 0;
      cursor: text;
      font-family: 'Roboto';
      outline: 0;
      padding: 14px;
    }
  }
}

.autoCompleteDiv {
  padding: 0 !important;
}

.autoCompleteDiv > input {
  font-family: inherit;
  border: 0;
  background-color: inherit;
  text-align: center;
  outline: none;
}

.outerContainer {
  position: relative;
  margin: 8px;
}
.hwRevisionSelect {
  width: 200px;
  height: 25px;
}

.fwTypeSelect {
  width: 150px;
  height: 25px;
}
