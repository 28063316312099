.container {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Verdana';
}
.chartContainer {
  min-height: 550px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
  position: relative;
  background-color: #e4eef1;
  margin-top: 20px;
}
.userInfo {
  align-self: self-start;
  margin-top: 5px;
  margin-left: 60px;
}
.userBackButton {
  color: #1976d2;
  cursor: pointer;
  margin-bottom: 20px;
}
.yAxis {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: black;
  height: 2px;
  width: 100%;
}
.yAxisLabel {
  position: absolute;
}

.sleepStageContainer {
  position: absolute;
  top: 300px;
  height: 100px;
  width: 100%;
}
.dataBlock {
  position: absolute;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  .dataBlockHover {
    display: none;
    background-color: beige;
    width: 350px;
    min-width: 350px;
    font-size: 15px;
    padding: 10px;
    position: relative;
    margin-top: 90px;
    left: 0;
    z-index: 3;
  }
  &:hover {
    .dataBlockHover {
      display: block;
    }
  }
}
.event {
  @extend .dataBlock;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  z-index: 2;
  &:hover {
  }
  .dataBlockHover {
  }
}
.eventLabel {
  position: absolute;
}
.eventLine {
  width: 1px;
  background-color: black;
  height: 221px;
  top: 40px;
  position: absolute;
}
.poiChangeContainer {
  position: absolute;
  top: 400px;
  height: 100px;
  width: 100%;
}
.eventsContainer {
  position: absolute;
  top: 200px;
  height: 100px;
  width: 100%;
}
.soundsContainer {
  position: absolute;
  top: 100px;
  height: 100px;
  width: 100%;
}
.connectionContainer {
  position: absolute;
  top: 50px;
  height: 10px;
  width: 100%;
}
.headerRow {
  display: flex;
  flex-direction: row;
  width: calc(100% - 120px);
  align-items: center;
  margin-top: 40px;
}
.timeSelectorHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.timeSelectorTopRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 130px;
}
.timeSelectorBottomRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.daySelector {
  align-self: flex-end;
  margin-left: 30px;
}
.buttonLoad {
  cursor: pointer;
  background-color: white;
  width: 70px;
  text-align: center;
  height: 30px;
  align-self: flex-end;
}
.legend {
  margin-top: 30px;
  background-color: #e4eef1;
  padding: 30px;
  border-radius: 10px;
  align-self: flex-start;
  margin-left: 57px;
  display: flex;
}
.legendBlock {
  width: 370px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.legendItem {
  width: 300px;
  border-radius: 5px;
  line-height: 30px;
  color: black;
  margin: 5px;
}
.legendItemCircle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: relative;
  margin: 5px;
  margin-left: -350px;
}
.legendItemText {
  position: absolute;
  width: 300px;
  line-height: 30px;
  color: black;
  left: 40px;
  border-radius: 5px;
}

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(127, 127, 127, 0.6);
  z-index: 10;
}
.loadingImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.trackerDataContainer {
  align-self: flex-start;
  flex-direction: column;
  display: flex;
  margin-top: 30px;
  margin-left: 60px;
}
.tracker {
  margin-bottom: 15px;
}
.trackerButton {
  color: blue;
  cursor: pointer;
}
.trackerData {
  margin-top: 15px;
}
