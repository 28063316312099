@import '../../PatientDashboardCommon.scss';


.errorMessage {
  margin-top: 20px !important;
  color: $color-red !important;
}

.successMessage {
  margin-top: 20px !important;
  color: $color-dark-blue !important;
}