@import '../../style.scss';


.headerContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.plusIcon {
  height: 20px;
  width: 20px;
}

.newPatient {
  color: $color-white !important;
}
