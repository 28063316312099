@import '../../PatientDashboardCommon.scss';

.card {
  @extend .columnContainer;
  padding: $spacing-medium;
  border: 1px solid $color-gray-200;
  border-radius: 16px;
  box-sizing: border-box;
}

.cardTitleContainer {
  @extend .columnContainer;
  gap: 0;
}

.vitalContainer {
  @extend .columnContainer;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  margin-top: $spacing-xs;
}

.vitalValueContainer {
  @extend .columnContainer;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.vitalValueText {
  text-align: center;
}

.vitalValueUnit {
  @extend .vitalValueText;
  color: $color-gray;
}

.redText {
  color: $color-red-600;
}

.yellowText {
  color: $color-yellow-400;
}
