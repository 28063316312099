@import '../../style.scss';

$nav-height: 72px;


.headerContainer {
  display: flex;
  max-height: $nav-height;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-medium $spacing-xl;
  box-sizing: border-box;
  border-bottom: 1px solid $color-gray-100;
}

.logoContainer {
  height: 100%;
  flex: 1;
}

.logoImage {
  height: 100%;
}

.groupContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: $spacing-large;
  margin: 0 $spacing-large;
}

.rightMenuContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: $spacing-medium;
}

.tab {
  cursor: pointer;
  border: none;
  margin: 0;
  background-color: transparent;
  font-family: 'Outfit';
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: $color-gray !important;
  padding: 0 0 0 0 !important;
  text-transform: none !important;
}

.tab.active {
   color: $color-gray-blue !important;
   font-weight: 800 !important;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 0.75px solid;
  border-color: $color-gray-100;
  background-color: $color-gray-50;
  white-space: nowrap;
  cursor: pointer;
}

.smallAvatar {
  @extend .avatar;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.personInitials {
  color: $color-gray;
}

.bellContainer {
  padding: $spacing-xs;
}

.menuItem {
  height: 24px;
  display: flex;
  align-items: center;
}

.menuItemText {
  margin-left: $spacing-xs;
}

.label {
  margin-left: $spacing-xs;
}

.dashboardTab {
  display: flex;
}
