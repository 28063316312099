.container {
  margin: 60px !important;
  min-width: 900px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: stretch !important;
  justify-content: flex-start !important;
  padding: 0 0 40px 0 !important;
}
.containerWithBackground {
  @extend .container;
  background-color: #f3f7fa !important;
  border-radius: 8px !important;
  border: 1px solid #d0dce2 !important;
  margin: 20px 0 0 !important;
  padding: 20px 20px 40px 20px !important;
}
.containerCreator {
  @extend .container;
  background-color: white !important;
  margin: 0 20px 20px !important;
}
.headerRow {
  margin-top: 21px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.headerRowRight {
  @extend .headerRow;
  justify-content: flex-end !important;
}
.header {
  font-size: 32px !important;
  margin-right: 9px !important;
}
.headerNameBox {
  display: flex !important;
  align-items: center;
}

.row {
  margin-top: 16px !important;
  display: flex !important;
  flex-direction: column !important;
  text-align: left;
}

.iconRowWrapper {
  display: flex !important;
  flex-direction: row !important;
}

.alertIconBox {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  margin-left: 4px !important;
  z-index: 3 !important;
  .hoverBox {
    display: none !important;
  }
  &:hover {
    .hoverBox {
      display: flex !important;
      border-radius: 8px !important;
      position: absolute !important;
      background-color: #f4bc2b !important;
      max-width: 200px !important;
      padding: 12px !important;
      margin-top: 100px !important;
      font-size: 16px !important;
    }
  }
}

.sleepNeedsInfoBox {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  margin-left: 4px !important;
  position: absolute !important;
  right: 4px !important;
  top: 4px !important;
  z-index: 3 !important;
  .hoverBox {
    display: none !important;
  }
  &:hover {
    .hoverBox {
      display: flex !important;
      flex-direction: column !important;
      border-radius: 8px !important;
      position: absolute !important;
      background-color: #154560 !important;
      padding: 12px !important;
      margin-top: 169px !important;
      text-wrap: nowrap;
      font-size: 16px !important;
      color: white;
      text-decoration: underline;
    }
  }
}
.sleepNeedsInfoText {
  font-size: 16px !important;
}
.infoIcon {
  width: 12px;
  height: 12px;
}
.linkIcon {
  width: 12px;
  height: 12px;
}

.rowHeader {
  font-size: 12px !important;
  margin-bottom: 4px !important;
  color: black !important;
}
.rowHeaderOrange {
  @extend .rowHeader;
  color: #f4bc2b !important;
}
.rowHeaderSleepLogs {
  @extend .rowHeader;
  font-size: 16px !important;
  margin-top: 20px !important;
}
.attentionIcon {
  width: 12px;
  height: 12px;
}

.rowContent {
  display: flex !important;
  align-items: center !important;
  position: relative;
}

.blockWithHeader {
  display: flex !important;
  flex-direction: column !important;
  margin-right: 8px;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
}
.blockHeader {
  font-size: 12px !important;
  margin-bottom: 4px !important;
  color: black !important;
  position: relative;
}
.blockHeaderInside {
  font-size: 12px !important;
}
.blockContent {
  width: 160px !important;
  padding-left: 12px !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: flex-start !important;
  height: 63px !important;
  font-size: 16px !important;
  color: black !important;
  border-radius: 5px !important;
  flex-direction: column !important;
}

.blockFooter {
  margin-top: 4px !important;
  font-size: 12px !important;
  color: #808080 !important;
}
.blockFooterAbsolute {
  @extend .blockFooter;
  position: absolute;
  top: 65px;
}

.block {
  display: flex !important;
  flex-direction: column !important;
}
.selectBlockWithHeader {
  @extend .blockWithHeader;
}
.blockTextInput {
  background-color: #f3f7fa !important;
  min-width: 160px !important;
  text-align: left;
}

.blockTextInputWhite {
  @extend .blockTextInput;
  background-color: white !important;
}
.rowTextInput {
  background-color: #f3f7fa !important;
}
.rowTextInputWhite {
  background-color: white !important;
}
.rowTextInputMargin {
  margin-right: 43px !important;
  background-color: #f3f7fa !important;
}
.rowTextInputMarginWhite {
  @extend .rowTextInputMargin;
  background-color: white !important;
}
.rowTextInputChanged {
  background-color: #fdf7df !important;
  border: 1px solid #f4bc2b !important;
}
.buttonRow {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.editButton {
  border-radius: 2px;
  color: #007eb5 !important;
  font-size: 12px !important;
  width: 66px !important;
  height: 46px !important;
  align-self: flex-start;
}

.sendButtonBox {
  display: flex !important;
  margin-left: 8px !important;
  flex-direction: column !important;
}

.sendButton {
  border-radius: 2px;
  width: 145px !important;
  height: 46px !important;
  background-color: #007eb5 !important;
  font-size: 12px !important;
  color: white !important;
}
.sendButtonNoteBox {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 4px !important;
}
.sendButtonNote {
  font-size: 12px !important;
  color: #428455 !important;
  margin-left: 4px !important;
}
.sendButtonNoteOrange {
  @extend .sendButtonNote;
  color: #f4bc2b !important;
}
.sendButtonNoteIcon {
  width: 14px;
  height: 14px;
}

.sendButtonOrange {
  @extend .sendButton;
  background-color: #f4bc2b !important;
}
.lunaImage {
  width: 32px;
  height: 32px;
  margin-left: 12px;
}

.pageDownIcon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.pageUpIcon {
  width: 16px;
  height: 16px;
}

.assignedInfoBox {
  display: flex !important;
  align-items: center;
}
.assignedIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.assignedInfoText {
  font-size: 16px !important;
  color: black !important;
  margin-right: 4px;
}
