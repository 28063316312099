@import '../../style.scss';

.container {
  max-width: 400px;
  min-width: 200px;
  margin-bottom: 32px;
}

.button {
  background-color: $color-dark-blue !important;
  &:hover {
    background-color: hex-to-rgba($color-dark-blue, 0.5) !important;
  }
}

.labelSelect {
  font-family: 'Outfit';
  color: $color-gray-700;
  font-weight: 400;
  margin-bottom: 2px;
  padding-left: 4px;
  font-size: 14px;
  line-height: 20px;
}
