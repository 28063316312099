@import '../../style.scss';

nav > ol {
  & > li, & > li span {
    color: $color-gray !important;
  }

  & > li:last-child span {
    color: $color-dark-blue-new !important;
  }
}
