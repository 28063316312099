$tile-color: #f3f7fa;
.container {
  border: 1px solid #007eb5 !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 8px;
}

.inputRow {
  display: flex !important;
  background-color: #f3f7fa !important;
  border-radius: 8px !important;
  height: 93px;
}
.inputContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin-right: 8px !important;
}
.inputContent {
  margin: 16px 0 0 16px !important;
  display: flex !important;
}
.inputLabel {
  font-size: 12px !important;
  margin-bottom: 4px;
}
.input {
  width: 242px !important;
  height: 43px;
  padding: 0 !important;
  text-align: left !important;
  background-color: white;
}
.dateBox,
.inputBox,
.descriptionBox {
  text-align: left !important;
  background-color: white !important;
  height: 43px;
  border-color: lightgray;
  justify-content: center !important;
  @media (min-width: 960px) {
    justify-content: flex-start !important;
  }
}

.dateBox,
.inputBox {
  width: 242px !important;
  font-size: 16px;
}
