@import '../../style.scss';

.container {
  display: flex;
  height: 100%;
  direction: ltr;
}

.sideBarContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.topMenuContainer {
  flex: 1;
  margin-bottom: 16px;
}

.contentContainer {
  display: flex;
  flex: 1;
}

.menuIcon {
  height: 20px;
  width: 20px;
}
