.tabs {
  border-bottom: 1px solid black;
}

.redDot {
  background-color: #ff0000;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  align-self: flex-start;
}

.tabLabel {
  font-size: 12px !important;
  line-height: 14px !important;
}
