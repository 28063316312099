@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import '../../style.scss';


:root {
  --font-color: $color-dark-blue-new;
  --font: 'Outfit';
  --common-height: 40px;
  --text-color: $color-white;
  --common-border-radius: 8px;
}

.action_button {
  margin-top: $spacing-xs;
  background-color: $color-gray-blue;
  color: $color-white;
  font-family: var(--font);
  font-size: 16px;
  line-height: 24px;
  height: 44px;
  border-radius: var(--common-border-radius);
}

.link_button {
  margin-bottom: $spacing-2xs;
  padding: 0;
  background: none;
  border: none;
  color: $color-gray-blue;
  font-family: var(--font);
  font-size: 14px;
  line-height: 20px;
}

.credentials_container {
  display: flex;
  flex-direction: column;
  font-family: var(--font);
  width: 350px;
}

.email,
.password {
  margin-bottom: $spacing-medium;
  padding: $spacing-xs $spacing-small;
  border: 1px solid $color-gray-200;
  color: $color-dark-blue-new;
  font-family: var(--font);
  font-size: 16px;
  line-height: 24px;
  outline: none;
  border-radius: var(--common-border-radius);
}

.label {
  color: $color-gray;
  font-family: var(--font);
  margin-bottom: $spacing-2xs;
}

.password_label_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top_container {
  display: flex;
  height: 100%;
}

.center_flex {
  align-items: center;
  justify-content: center;
  display: flex;
}

.left_container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacing-xl;
}

.login_container {
  @extend .center_flex;
  flex: 1;
  flex-direction: column;
}

.bottom_container {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: $spacing-xl;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.envelope_img {
  margin-right: $spacing-2xs;
}

.envelope_container {
  align-items: center;
  justify-content: center;
  display: flex;
}

.login_header {
  margin-bottom: $spacing-xl;
}

.footer_label {
  color: $color-gray;
  font-family: var(--font);
}

.mail_to {
  @extend .footer_label;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.right_container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-image: url('../../assets/images/loginBg.png');
  background-size: cover;
  height: 100%;
}

.logo_container {
  @extend .center_flex;
  position: absolute;
  left: 0;
  top: 0;
  padding: $spacing-xl;
  box-sizing: border-box;
  width: 100%;
  gap: $spacing-2xs;
}

.logo_text {
  color: $color-white;
}

.health_container {
  @extend .center_flex;
  padding: $spacing-xl;
  box-sizing: border-box;
  min-width: 350px;
  flex-direction: column;
  flex: 1;
}
