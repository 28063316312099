@import '../../style.scss';

.button {
  box-sizing: border-box;
  padding: 9px 13px !important;
  border: 1px solid $color-gray-200 !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: $color-dark-blue-new !important;
  text-transform: none !important;
}

.button.active {
   font-weight: 800 !important;
}

.menuItemText {
}

.menuItemIcon {
  width: 20px;
  height: 20px;
}
