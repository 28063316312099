@import '../../../style.scss';


.topContainer {
  background-color: $color-gray-50;
  height: 400px;
  padding: 60px;
  font-family: outfit;
  border-radius: 16px;
  margin-top: 32px;
}

.subContainer {
  width: 512px;
  min-width: 521px;
}

.headerText {
  margin-top: 32px;
}

.peopleGroupImg {
  width: 80px;
  height: 80px;
};

.supportingText {
  font-size: 16px;
  line-height: 24px;
  color: $color-gray;
}

.newPatientContainer {
  margin-top: 32px;
}

.plusIcon {
  height: 20px;
  width: 20px;
}

.newPatient {
  color: $color-white !important;
  height: 48px;
  width: 138px;
}
