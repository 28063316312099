@import '../../style.scss';

.container {
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  position: sticky;
  padding-top: $spacing-xl;
  padding-bottom: $spacing-large;
}

.menuButton {
  color: $color-dark-blue-new !important;
  background-color: $color-light-blue !important;
  margin: 0 $spacing-medium auto $spacing-xl !important;
}

.header {
  padding: 0 $spacing-xl;
  display: flex;
  flex-direction: column;
  gap: $spacing-large;
  align-items: start;

  @media (max-width: 768px) {
    padding-left: $spacing-medium;
  }
}

.headerItem {
  align-self: stretch;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 0 $spacing-xl $spacing-xl;
}
