@import '../../style.scss';

.container {
  display: flex;
  direction: ltr;
  min-height: 100%;
}

.sideBarContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.topMenuContainer {
  flex: 1;
  margin-bottom: 16px;
}

.adminHeader {
  text-align: center;
  margin-top: 20px;
}

.menuIcon {
  height: 20px;
  width: 20px;
}
