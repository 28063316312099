.container {
  margin-top: 8px !important;
  max-width: 700px !important;
}
.cardContent {
  margin-left: 16px !important;
  font-size: 24px !important;
}
.cardRight {
  margin-right: 16px !important;
}
.chipBedTime {
  background-color: #ecf8fd !important;
  color: #007eb5 !important;
  font-size: 16px !important;
  margin-right: 8px;
}

.chipWakeTime {
  background-color: #fdf7df !important;
  color: #f4bc2b !important;
  font-size: 16px !important;
}
